<template>
  <div style="margin-top: 20px">
    <el-card :body-style="{ paddingBottom: 0 }">
      <div style="display: flex; justify-content: space-between">
        <form-create
          v-model="where"
          :components="[
            {
              name: 'form-item',
              props: {
                prop: 'keyword',
                label: '课程名称',
                style: { width: '550px' },
                labelWidth: '80px',
              },
            },
          ]"
          inline
        >
          <el-form-item class="operate" slot="after">
            <el-button
              type="primary"
              size="small"
              @click="$refs.table.reset(where)"
              >查询</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="$refs.table.reset((where = {}))"
              >重置</el-button
            >
          </el-form-item>
        </form-create>
        <div>
          <el-button
          type="primary"
          style="height: 30px"
          size="small"
          @click="$refs.table.reset(where)"
          >查看有效课程</el-button>
          <el-button
          type="primary"
          style="height: 30px"
          size="small"
          @click="checkAllCrouse"
          >查看全部课程</el-button>
        <el-button
          type="primary"
          style="height: 30px"
          :disabled="!$isPowers('courseCreate')"
          size="small"
          @click="$handleRoute('teachingCourseCreateNew')"
          >创建课程</el-button>
          <el-button
          type="danger"
          style="height: 30px"
          size="small"
          @click="delCourse"
          >删除课程</el-button>
        </div>

      </div>
    </el-card>
    <div
      class="operate"
      style="margin-top: 15px; display: flex; justify-content: flex-end"
    >
      <!-- 隐藏文件夹功能 -->
      <!-- <div>
          <el-checkbox
              class="el-button--small"
              :value="selectData.length >= (($refs.table||{}).currentPageSize || 12)"
              @change="selectData = $event ? [...$refs.table.parseData] : [];"
              v-if="$isPowers(['courseFolderRemove','courseFolderMove'])"
          >全选</el-checkbox>
        </div> -->

      <el-button-group>
        <!-- <el-button type="primary" :disabled="!$isPowers('courseCreate')" size="small" @click="$handleRoute('teachingCourseCreateNew')">创建课程</el-button> -->
        <!-- <el-button type="danger"
                     v-if="$isPowers(['courseFolderRemove'])"
                     plain size="small"
                     :disabled="selectData.length === 0"
                     @click="$handleConfirm('你确定将选中课程的文件夹移除吗？','/xapi/course.course/removeFolder',{ids:selectData.map(d=>d.id),folder_id:($attrs.folderData || {}).id || 0},'routeUpdate',{title:'移除文件夹'})"
          >移除文件夹</el-button> -->

        <!-- 隐藏文件夹功能 -->
        <!-- <el-button type="danger"
                     v-if="$isPowers(['courseFolderRemove'])"
                     plain size="small"
                     :disabled="selectData.length === 0"
                     @click="remove"
          >移除文件夹</el-button>
          <el-button type="warning" plain size="small"
                     v-if="$isPowers(['courseFolderMove'])"
                     :disabled="selectData.length === 0"
                     @click="$refs.changeDialog.visibled(true)"
          >文件夹变更</el-button> -->

        <!-- <el-button type="danger" plain size="small"
                     @click="$handleConfirm('你确定删除该文件夹吗？','/xapi/com.folder/delete',{id:($attrs.folderData || {}).id},'handleCommand:routeRefresh',{title:'删除文件夹'})"
                     v-if="$isPowers(['courseFolderDel']) && ($attrs.folderData || {}).id > 0"
          >删除文件夹</el-button>
        </el-button-group> -->

        <!-- 隐藏文件夹功能 -->
        <!-- <el-button type="danger" plain size="small"
                     @click="deleteFolder"
                     v-if="$isPowers(['courseFolderDel']) && ($attrs.folderData || {}).id > 0"
          >删除文件夹</el-button>-->
      </el-button-group>
    </div>

    <!-- <diy-dialog ref="changeDialog" title="目录变更" center>
        <form-create ref="changeForm" :components="[
              {name:'form-item',props:{prop:'folder_id',label:'选择目录',required:true,type:'select',data:{url:'/xapi/com.folder/list',params:{type:'t_course'},labelOptions:{value:'id',label:'name'}}}},
            ]" :label-width="'100px'">
        </form-create>
        <div slot="footer">
          <el-button style="margin-right: 50px;" type="primary" @click="$refs.changeDialog.visibled(false)">取 消</el-button>
          <el-button type="primary" @click="$refs.changeForm.validate((data,valid)=>{
          if(valid){
            $http.post('/xapi/course.course/changeFolder' , {...(data || {}),ids:selectData.map(d=>d.id)}).then(({msg})=>{
                          $handleMessage(msg || '操作成功！' , 'success' , ()=>{routeUpdate();$refs.changeDialog.visibled(false) })
                }).catch(()=>{})
          }
        })">确 定</el-button>
        </div>
      </diy-dialog> -->
    <el-card
      style="margin-top: 15px"
      :body-style="{ padding: '20px 10px' }"
      class="table-box"
    >
      <com-table
        ref="table"
        :data="{
          url: '/xapi/course.course/myList',
          params: {...$route.params, ...$route.query,is_invalid},
        }"
        :columns="[]"
        :page-size="36"
        :where="where"
        :row-page="6"
        list-type="table-list"
        @done="selectData = []"
      >
        <div style="padding: 0 4px; margin-bottom: 15px" slot-scope="{ row }">
          <el-card
            :body-style="{ padding: 0 }"
            :style="{ borderRadius: '15px 15px 0 0', width: '100%' }"
          >
            <div style="width: 100%; height: 160px; position: relative">
              <div style="position:absolute;right:10px;top:5px">
                  <el-checkbox
                      :value="selectData.filter(d=>d.id === row.id).length > 0"
                      @change="(e)=>{
                        if(e){
                          selectData.filter(d=>d.id === row.id).length === 0 && selectData.push(row);
                        }else{
                          selectData = selectData.filter(d=>d.id !== row.id);
                        }
                      }"
                  ></el-checkbox>
                </div>
              <el-image
                :src="row.cover"
                style="width: 100%; height: 10rem"
                @click="
                  $handleRoute({ course_id: row.id }, 'teachingCourseDetailNew')
                "
              >
              </el-image>
              <div class="tags" style="position: absolute; top: 0; left: 0">
                <div class="tag pay" v-if="row.price > 0">付费</div>
                <div class="tag free" v-else>免费</div>
              </div>
              <!-- <div style="width:100%;height:10rem" @click="$handleRoute({course_id:row.id} , 'teachingCourseDetail')">
                  <img style="width:100%;height:100%" :src="row.cover" alt="">
                </div> -->
            </div>
            <div
              class="shenluehao-2"
              style="display: flex; align-items: center; padding: 4px 14px"
            >
              <!-- <el-link :disabled="!$isPowers('courseEdit')" @click="$handleRoute({course_id:row.id} , 'teachingCourseEditNew')">
                    <i class="el-icon-edit"
                       style="margin-right: 5px;font-weight: 700;color: var(--color-primary);font-size: 145%;"
                       :title="'编辑课程'"
                    ></i>
                  </el-link> -->
              <span
                class="title1"
                @click="
                  $handleRoute({ course_id: row.id }, 'teachingCourseDetailNew')
                "
                >{{ row.course_name }}</span
              >
            </div>
            <div class="time">创建时间：{{ row.create_time }}</div>
            <div
              :style="{
                padding: '0px 14px',
                borderBottom: '1px solid #e6e6e6',
              }"
            >
              <!-- <div
                :style="{
                  fontSize: '90%',
                  color: 'rgba(254,81,52,1)',
                }"
              >
                {{ row.price > 0 ? "¥" + row.price : "免费" }}
              </div> -->
              <div style="font-size: 12px; color: #9199a1; margin: 4px 0 8px">
                {{ row.cid_name }} · {{ row.people || 0 }} 人报名
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 14px;
                  height: 40px;
                "
              >
                <el-image
                  v-if="row.lecturer"
                  style="width: 30px; height: 30px; border-radius: 50%"
                  :src="row.lecturer.headimg"
                  fit="cover"
                ></el-image>

                <div
                  style="margin-left: 5px; font-weight: 700; flex: 1"
                  class="shenluehao"
                >
                  {{ row.teach_name }}
                </div>
                <div
                style="display: flex;align-items: center;"
                  :style="{
                    fontSize: '90%',
                    textAlign: 'right',
                  }"
                >
                  是否发布
                  <el-switch
                    v-model="row.is_up"
                    :active-value="1"
                    :inactive-value="0"
                    :disabled="!$isPowers('courseUp')"
                    @change="setShowHome(row, $event)"
                  />
                </div>
              </div>
              <div :style="{ padding: '8px 0px' }">
                <div
                  style="display: flex; line-height: 40px"
                  :style="{
                    justifyContent:
                      row.price > 0 ? 'space-between' : 'flex-end',
                  }"
                >
                  <div
                    v-if="row.price > 0"
                    style="color: #f1272b; font-weight: bold"
                  >
                    ￥{{ row.price }}
                  </div>
                  <div>
                    <el-button
                      size="mini"
                      type="primary"
                      @click="applyManage(row)"
                      >报名管理</el-button
                    >
                    <el-button
                      size="mini"
                      type="primary"
                      :disabled="!$isPowers('courseEdit')"
                      @click="
                        $handleRoute(
                          { course_id: row.id },
                          'teachingCourseEditNew'
                        )
                      "
                      >编辑</el-button
                    >
                    <!-- <el-switch
                      v-model="row.is_up"
                      active-color="#33AA98"
                      @change="changeTeam(row,$event)"
                      :active-value="1"
                      :inactive-value="0"
                      inactive-color="#DCDFE6">
                    </el-switch> -->
                  </div>
                  <!-- <el-avatar v-if="row.lecturer" style="width: 40px;height: 40px;" :src="row.lecturer.headimg"></el-avatar> -->

                  <!-- <diy-form-item :item-type="0" type="switch" prop="is_up"
                                   label="是否发布"
                                   @update="handleEditChange(row , $event,row.is_up+'' === '1' ? '你确定发布该课程吗？' : '你确定取消发布该课程吗？',row.is_up+'' === '1' ? '发布课程' : '取消发布')"
                                   v-model="row.is_up"
                                   :disabled="!$isPowers('courseUp')"
                                   :active-value="1"
                                   :inactive-value="0"
                    ></diy-form-item> -->
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </com-table>
    </el-card>
  </div>
</template>
  
  <script>
import { updateCourse } from "@/api/course.js";

export default {
  data() {
    return {
      where: {},
      selectData: [],
      is_invalid:0
      // value:0
    };
  },
  created() {
    localStorage.removeItem("pageSize");
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
          // console.log('回车事件')
          // 调用你的搜索方法
          if(this.$refs.table){
            this.$refs.table.reset(this.where)
          }
        }
      });
    });
  },
  methods: {
    routeUpdate() {
      this.is_invalid=1
      console.log("初始化");
      // console.log(this.$attrs.folderData,"$attrs.folderData");
      this.selectData = [];
      this.$refs.table && this.$refs.table.reset();
      this.$parent.activeType = "t_course";
      this.$parent.powerName = "course";
    },
    checkTotal(row) {
      this.$handleRoute({ id: row.id }, "/teaching/totalDetail", "_blank");
    },
    applyManage(row) {
      this.$handleRoute({ course_id: row.id },'teachingCourseStudy')

      // this.$handleRoute("/teaching/attendance/list");
    },
    changeTeam(row) {
      console.log(row, "rowrow");
      // return
      // if(this.value==0){
      this.$confirm("你确定创建团队吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/xapi/course.course/createGroup", { course_id: row.id })
            .then((res) => {
              console.log(res, "essssssssssss");
              this.$message({
                type: "success",
                message: "创建成功",
              });
            });
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "已取消创建",
          });
        });
      // }
    },
    deleteFolder() {
      this.$confirm("你确定删除该文件夹吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/xapi/com.folder/delete", {
              id: (this.$attrs.folderData || {}).id,
            })
            .then((res) => {
              console.log(res, "你确定删除该文件夹吗");
              this.$message({
                type: "success",
                message: res.msg,
              });
              // this.getList()
              // this.routeUpdate()
              this.$handleRoute({ folder_id: 0 }, true);
            });
        })
        .catch((e) => {
          console.log(e, "eeeeeee");
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // getList(){
    //   this.$http.post('/xapi/com.folder/list' , {type:'t_course',pageSize:100})
    //     .then(res=>{

    //     })
    // },
    // 查看全部课程
    checkAllCrouse(){
      this.$refs.table.reset({is_invalid:""})
    },
    delCourse() {
      this.$confirm("你确定将选中课程的删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(this.selectData,"selectDataselectData")
          // return
          this.$http
            .post("/xapi/course.course/setValid", {
              ids: this.selectData.map((d) => d.id),
            })
            .then((res) => {
              // console.log(res,"rsssssssss");
              this.$message({
                type: "success",
                message: res.msg,
              });
              // this.routeUpdate();
              this.$refs.table.reset();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 是否发布作业
    setShowHome(row, val) {
      console.log(val, "valll");
      this.$confirm(
        `您确认${val == "1" ? "发布" : "取消发布"}该课程吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          let data = {
            field: "is_up",
            id: row.id,
            value: val,
          };
          updateCourse(data)
            .then((res) => {
              this.$message({
                type: "success",
                message: val == "1" ? "发布成功" : "取消发布成功",
              });
            })
            .catch((err) => {
              if (val == "0") {
                row.is_up = 1;
              } else {
                row.is_up = 0;
              }
            });
        })
        .catch((err) => {
          console.log(row.is_up, "row.is_up");
          if (val == "0") {
            row.is_up = 1;
          } else {
            row.is_up = 0;
          }
        });
    },
    handleEditChange(row, { value, oldValue, prop }, confirm, confirm_title) {
      if (confirm && confirm_title) {
        console.log(222);
        this.$handleConfirm(
          confirm,
          "/xapi/course.course/updateField",
          { field: prop, id: row.id, value: value },
          {
            title: confirm_title,
            callback: () => {
              row[prop] = oldValue;
            },
          }
        );
      } else {
        console.log(111);
        this.$http
          .post("/xapi/course.course/updateField", {
            field: prop,
            id: row.id,
            value: value,
          })
          .then()
          .catch(() => {
            row[prop] = oldValue;
          });
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
/deep/ .table-box {
  .el-table .el-table__cell.table-cell-image,
  .el-table .el-table__cell.table-cell-image,
  .el-table .el-table__cell.table-cell-image.is-center {
    padding: 0 !important;
  }
  .el-checkbox__input .el-checkbox__inner {
    width: 20px;
    height: 20px;
  }
  .el-checkbox__input .el-checkbox__inner::after {
    border-width: 2px;
    height: 10px;
    left: 7px;
    top: 2px;
    width: 4px;
  }
  .el-button--mini{
    padding: 7px 7px !important;
  }
}
.title1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: bold;
}
.time {
  font-size: 0.8rem;
  box-sizing: border-box;
  padding: 0 14px;
  font-weight: 400;
  color: #646464;
  line-height: 1.5rem;
}
.tags {
  width: 46px;
  height: 22px;
  .tag {
    border-radius: 8px 0px 15px 0px;

    width: 46px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    font-size: 12px;
    color: #ffffff;
  }
  .free {
    background: #e98000;
  }
  .pay {
    background-color: #f64646;
  }
}
</style>